import React from 'react';
import { graphql } from 'gatsby';
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers';
import GraphQLErrorList from '../components/graphql-error-list/graphql-error-list';
import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import Hero from '../components/hero/hero';
import StoryList from '../components/story-list/story-list';
import Icon from '../components/icon';
import CenterColumn from '../components/center-column/center-column';

import { Box, Heading } from 'grommet';

import WorldsList from '../components/worlds-list/worlds-list';

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    minecraftWorlds: allSanityMinecraftWorld {
      edges {
        node {
          id
          title
          foundedDate
          mainImage {
            ...Image
          }
          slug {
            current
          }
        }
      }
    }
    valheimWorlds: allSanityValheimWorld {
      edges {
        node {
          id
          title
          foundedDate
          mainImage {
            ...Image
          }
          slug {
            current
          }
        }
      }
    }
    reviews: allSanityReview(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          _type
          id
          title
          game {
            title
            releaseDate
          }
          slug {
            current
          }
          recommended
          reason
          mainImage {
            ...CaptionedImage
          }
        }
      }
    }
    articles: allSanityArticle(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          _type
          id
          title
          subtitle
          slug {
            current
          }
          mainImage {
            ...CaptionedImage
          }
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  const minecraftWorlds = (data || {}).minecraftWorlds
    ? mapEdgesToNodes(data.minecraftWorlds).filter(filterOutDocsWithoutSlugs)
    : [];

  const valheimWorlds = (data || {}).minecraftWorlds
    ? mapEdgesToNodes(data.valheimWorlds).filter(filterOutDocsWithoutSlugs)
    : [];

  const reviews = (data || {}).reviews
    ? mapEdgesToNodes(data.reviews).filter(filterOutDocsWithoutSlugs)
    : [];

  const articles = (data || {}).articles
    ? mapEdgesToNodes(data.articles).filter(filterOutDocsWithoutSlugs)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <h1 hidden>Welcome to {site.title}</h1>
      <Hero
        imageUrl="/images/pinksun.jpg"
        subtitle="Gatsby Quest is my project to publish gaming and web development-related content, for fun."
        title="Howdy!"
      />
      <CenterColumn>
        <Heading level="2">Minecraft Worlds</Heading>
        {minecraftWorlds && (
          <WorldsList worlds={minecraftWorlds} urlType="minecraftWorld" />
        )}
        <Heading level="2">Valheim Worlds</Heading>
        {valheimWorlds && (
          <WorldsList worlds={valheimWorlds} urlType="valheimWorld" />
        )}
        <Heading level="2">Game Reviews</Heading>
        <StoryList stories={reviews} />
        <Heading level="2">Articles</Heading>
        <StoryList stories={articles} />
        <Box margin={{ vertical: 'xlarge' }}>
          <Box alignSelf="center">
            <Icon symbol={'minecraft'} />
          </Box>
        </Box>
      </CenterColumn>
    </Layout>
  );
};

export default IndexPage;
