import React from 'react';

import {
  Card,
  CardBody,
  Anchor,
  Heading,
  Text,
  Image,
  Box,
  ResponsiveContext
} from 'grommet';

import { buildImageObj, slugToUrl } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';

const StoryList = ({ stories }) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <Box margin={{ bottom: 'large' }}>
      {stories.map(({ title, slug, mainImage, _type, reason, subtitle }) => (
        <Card margin={{ vertical: 'small' }}>
          <Box direction={size === 'small' ? 'column' : 'row'}>
            <Box>
              <Image
                src={imageUrlFor(buildImageObj(mainImage))
                  .width(225)
                  .height(Math.floor((9 / 16) * 225))
                  .fit('crop')
                  .auto('format')
                  .url()}
                a11yTitle={title}
              />
            </Box>

            <CardBody pad={{ horizontal: 'medium', vertical: 'small' }}>
              <Heading level="3">
                <Anchor href={slugToUrl(slug.current, _type)}>{title}</Anchor>
              </Heading>
              <Text>{_type === 'review' ? reason : subtitle}</Text>
            </CardBody>
          </Box>
        </Card>
      ))}
    </Box>
  );
};

StoryList.defaultProps = {
  stories: []
};

export default StoryList;
